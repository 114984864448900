import { Row, Col, message, Table, Pagination, Button, Modal, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useCallback, useEffect, useState } from "react";
import { GrView } from "react-icons/gr";
import styled from "styled-components";
import { NavLink, useSearchParams } from "react-router-dom";
import { IPo } from "common/interface";
import { createManualPO, downloadPO, getAllPOs } from "store/po/poSlice";
import { getDateAndTimeFromISODate, getDateFromISODate } from "common/utils";
import { AppActionStrip } from "components/AppActionStrip/AppActionStrip";
import { AppFilterButton, AppFilter, AppPoFilter } from "components/AppFilter";
import { getAllVendors } from "store/vendor/vendorSlice";
import { BiDownload } from "react-icons/bi";

type PartialPOType = Partial<IPo> & { key: string };
const MESSAGE_KEY = "all_pos";

const headerKeys: { [key: string]: string } = {
  id: "PO ID",
  vendorId: "Vendor",
  // extPoIds: "Ext PO ID",
  orderDate: "Order Date",
  // expectedDeliveryDate: "Expected Delivery",
  receivedDate: "Received On",
  status: "Status",
  skuCount: "Item Count",
  totalAmount: "Amount",
  actions: "Actions",
};

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const StyledActionLink = styled(NavLink)`
  border: 1px solid;
  padding: 0.2rem;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AllPOPage = () => {
  useDocumentTitle("All Purchase Orders");
  const dispatch = useAppDispatch();
  const [columns, setColumns] = useState<ColumnsType<PartialPOType>>();
  const [rows, setRows] = useState<PartialPOType[]>();
  const { pos, status, error } = useAppSelector((state) => state.po);
  const [filterState, setFilterState] = useState(false);
  const [searchParams] = useSearchParams();
  const [filterCount, setFilterCount] = useState(0);
  const { vendors } = useAppSelector((state) => state.vendors);

  const getPOData = useCallback(
    (pageNum = 1, pageSize = 25, filter = "") => {
      dispatch(getAllPOs({ pageNo: pageNum, pageSize, filter, isGrnRequest: false, }));
    },
    [dispatch]
  );

  const getVendorData = useCallback(
    (pageNum = 1, pageSize = 100, filter = "") => {
      if (vendors === undefined) {
        dispatch(getAllVendors({ pageNo: pageNum, pageSize, filter }));
      }
    },
    [dispatch]
  );

  const onDownloadPo = ((poId: string) => {
    const id = Number(poId);

    if (!isNaN(id)) {
      dispatch(downloadPO({ id }));
    }
  });
  
  const onCreateManualPOConfirm = (() => {
    dispatch(createManualPO());
  });


  useEffect(() => {
    getVendorData();
  }, []);


  useEffect(() => {
    const filter = searchParams.toString();
    setFilterCount(
      Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
    );
    getPOData(1, 25, filter);
  }, [dispatch, getPOData, searchParams]);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "Loading Purchase Orders", key: MESSAGE_KEY });
    } else if (status === "success") {
      message.success({
        content: "Purchase Orders loaded!",
        key: MESSAGE_KEY,
      });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }
     else if (status === "creating") {
      message.loading({ content: "Creating Manual PO", key: MESSAGE_KEY });
    }
    else if (status === "saved") {
      message.success({ content: "Manual PO succefully created", key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);

  useEffect(() => {
    const poData = pos && pos.data ? pos.data : [];
    const data: PartialPOType[] = poData.map((po, i) => {
      const {
        id,
        poId,
        vendorId,
        vendorName,
        vendorCode,
        vendorDetails,
        // extPoIds,
        orderDate,
        expectedDeliveryDate,
        receivedDate,
        status,
        skuCount,
        totalAmount,
      } = po;

      const newRow = {
        id,
        poId,
        vendorId,
        vendorName,
        vendorCode,
        vendorDetails,
        // extPoIds,
        orderDate,
        expectedDeliveryDate,
        receivedDate,
        status,
        skuCount,
        totalAmount,
      };
      newRow.receivedDate = getDateAndTimeFromISODate(newRow.receivedDate);
      newRow.expectedDeliveryDate = getDateAndTimeFromISODate(newRow.expectedDeliveryDate);
      return { ...newRow, key: `po-${i}`, ellipses: true };
    });

    const tableHeaders: ColumnsType<PartialPOType> = [
      ...Object.keys(headerKeys).map((key) => ({
        key,
        title: headerKeys[key],
        dataIndex: key,
        render: (text: string, record: PartialPOType) => {
          if (key === "actions") {
            return (
              <Row
                align="middle"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Col>
                  <StyledActionLink to={`/po/requests/${record.id}`}>
                    <GrView />
                  </StyledActionLink>
                </Col>
                <Col>
                  <Button
                    shape="circle"
                    size="small"
                    style={{ border: "1px solid green", marginLeft: "1rem" }}
                    onClick={() => onDownloadPo(record.id!)}
                    icon={<BiDownload style={{ marginTop: ".2rem" }} />}
                  />
                </Col>
              </Row>
            );
          }

          if (key === "orderDate" || key === "expectedDeliveryDate") {
            return <div>{getDateFromISODate(record[key] || "")}</div>;
          }

          if (key === "vendorId") {
            return (
              <NavLink to={`/vendors/edit/${record.vendorId}`}>{record.vendorCode} || {record.vendorName}</NavLink>
            );
          }

          if (key === "id") {
            return (
              <NavLink to={`/po/requests/${record.id}`}>{record.poId}</NavLink>
            );
          }

          return <>{text}</>;
        },
      })),
    ];

    setColumns(tableHeaders);
    setRows(data);
  }, [pos]);

  const Paginator = () => {
    if (pos) {
      const { pageNo, pageSize, total } = pos;
      return (
        <StyledPagination
          current={pageNo}
          pageSize={pageSize}
          total={total}
          onChange={(page, size) => {
            const filter = searchParams.toString();
            getPOData(page, size, filter);
          }}
        />
      );
    }

    return <></>;
  };

  const [isFirstDialogOpen, setIsFirstDialogOpen] = useState(false);
  const [isSecondDialogOpen, setIsSecondDialogOpen] = useState(false);

  const showFirstDialog = () => {
    setIsFirstDialogOpen(true);
  };

  const handleFirstDialogOk = () => {
    setIsFirstDialogOpen(false);
    setIsSecondDialogOpen(true);
  };

  const handleFirstDialogCancel = () => {
    setIsFirstDialogOpen(false);
  };

  const handleSecondDialogOk = () => {
    console.log("Success");
    onCreateManualPOConfirm();
    setIsSecondDialogOpen(false);
  };

  const handleSecondDialogCancel = () => {
    setIsSecondDialogOpen(false);
  };

  const modalFooter = (onOk: any, onCancel: any) => (
    <Row justify="end">
      <Space>
        <Button onClick={onCancel}>
          No
        </Button>
        <Button type="primary" onClick={onOk}>
          Yes
        </Button>
      </Space>
    </Row>
  );

  return (
    <>
      <>
        <Row justify="space-between" align="middle">
          <Col>
            <AppPageHeader title={"All Purchase Orders"} />
          </Col>
          <Col>
            <Button type="primary" onClick={showFirstDialog}>
              Create Shortfall PO
            </Button>
          </Col>
        </Row>

        <Modal
          title="Confirmation Required"
          open={isFirstDialogOpen}
          onOk={handleFirstDialogOk}
          onCancel={handleFirstDialogCancel}
          footer={modalFooter(handleFirstDialogOk, handleFirstDialogCancel)}
        >
          <p>This can be executed only once a day. Are you sure you want to create short fall?</p>
        </Modal>

        <Modal
          title="Final Confirmation"
          open={isSecondDialogOpen}
          onOk={handleSecondDialogOk}
          onCancel={handleSecondDialogCancel}
          footer={modalFooter(handleSecondDialogOk, handleSecondDialogCancel)}
        >
          <p>This action is irreversible. Are you sure to proceed?</p>
        </Modal>
      </>
      <Row>
        <AppActionStrip>
          <div className="action-strip__item">
            <AppFilterButton
              active={filterState}
              onClick={() => setFilterState(!filterState)}
              filterCount={filterCount}
            />
          </div>
        </AppActionStrip>
      </Row>
      <Row style={{ marginBottom: "1rem" }}>
        <AppFilter expanded={filterState}>
          <AppPoFilter />
        </AppFilter>
      </Row>
      {rows && rows.length ? (
        <Row>
          <Col xs={24}>
            <Table
              columns={columns}
              dataSource={rows}
              scroll={{ x: "100vh", y: "65vh" }}
              size="small"
              pagination={false}
              loading={status === "loading"}
              bordered
            />
          </Col>
          <Col xs={24}>
            <Paginator />
          </Col>
        </Row>
      ) : (
        status !== "loading" && <>No Purchase Orders to show.</>
      )}
    </>
  );
};

export default AllPOPage;
